import { Link } from "react-router-dom";
const Forgot = () => {
  const forgotHandler = (evt) => {
    evt.preventDefault();
    console.log("forgot Password", evt);
  };
  return (
    <div className="passwordBox animated fadeInDown">
      <div className="row">
        <div className="col-md-12">
          <div className="ibox-content">
            <h2 className="font-bold">Forgot password</h2>

            <p>Enter your Mobile number will be reset and emailed to you.</p>

            <div className="row">
              <div className="col-lg-12">
                <form className="m-t" onSubmit={forgotHandler}>
                  <div className="form-group">
                    <input
                      type="mobile"
                      className="form-control"
                      placeholder="Email address"
                      required={true}
                    />
                  </div>
                  <button
                    type="submit"
                    className="btn btn-primary block full-width m-b"
                  >
                    Send new password
                  </button>
                  <Link to="/">Sign In</Link>|
                  <Link to="/register">Create an account</Link>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div className="row">
        <div className="col-md-6">Copyright RMS App</div>
        <div className="col-md-6 text-right">
          <small>© 2022-2023</small>
        </div>
      </div>
    </div>
  );
};

export default Forgot;
