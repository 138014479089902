import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";

const RestaurantList = (prop) => {
  return (
    <div className="content-section implementation datatable-doc-demo">
      <div className="card">
        <DataTable
          value={prop.rest}
          filterDisplay="menu"
          selectionMode="single"
          resizableColumns
          reorderableColumns
          scrollable
          scrollHeight="400px"
        >
          <Column
            columnKey="id"
            field="id"
            header=""
            sortField="id"
            filterField="id"
            sortable
            filter
          ></Column>
          <Column
            columnKey="title"
            field="title"
            header="Restaurant"
            sortField="title"
            filterField="title"
            sortable
            filter
          ></Column>
          <Column
            columnKey="address"
            field="address"
            header="Address"
            sortField="address"
            filterField="address"
            sortable
            filter
          ></Column>
          <Column
            columnKey="district"
            field="district"
            header="District"
            sortField="district"
            filterField="district"
            sortable
            filter
          ></Column>
          <Column
            columnKey="state"
            field="state"
            header="State"
            sortField="state"
            filterField="state"
            sortable
            filter
          ></Column>
          <Column
            columnKey="lic_no"
            field="lic_no"
            header="Fssai No"
            sortField="lic_no"
            filterField="lic_no"
            sortable
            filter
          ></Column>
          <Column
            columnKey="rating"
            field="rating"
            header="Rating"
            sortField="rating"
            filterField="rating"
            sortable
            filter
          ></Column>
          <Column
            columnKey="status"
            field="status"
            header="Active"
            sortField="status"
            filterField="status"
            sortable
            filter
          ></Column>
        </DataTable>
      </div>
    </div>
  );
};
export default RestaurantList;
