export const getHeader = (method, body) => {
  return {
    method: method,
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
    },
    body: body ? JSON.stringify(body) : null,
  };
};
