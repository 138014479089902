import React, { lazy, Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import // ChangePassword,
// Dashboard,
// Forgot,
// Invoice,
// LoginPage,
// Menu,
// NoPage,
// Order,
// Register,
// Restaurant,
// Settings,
// Users,
"../pages";
import Layout from "./Layout";
import Public from "./Public";
// const Public = lazy(() => import("./Public"));
// const Layout = lazy(() => import("./Layout"));
const LoginPage = lazy(() => import("../pages/LoginPage"));
const Forgot = lazy(() => import("../pages/Forgot"));
// const ChangePassword = lazy(() => import("./pages/ChangePassword"));
const Dashboard = lazy(() => import("../pages/Dashboard"));
const Invoice = lazy(() => import("../pages/Invoice"));
const Menu = lazy(() => import("../pages/Menu"));
const Order = lazy(() => import("../pages/Order"));
const Register = lazy(() => import("../pages/Register"));
const Restaurant = lazy(() => import("../pages/Restaurant"));
const Settings = lazy(() => import("../pages/Settings"));
const Users = lazy(() => import("../pages/Users"));
const NoPage = lazy(() => import("../pages/NoPage"));

const Routers = (props) => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Public />}>
          <Route
            index
            element={
              <Suspense fallback={<>...</>}>
                <LoginPage />
              </Suspense>
            }
          />
          <Route
            path="forgotpassword"
            element={
              <Suspense fallback={<>...</>}>
                <Forgot />
              </Suspense>
            }
          />
          <Route
            path="register"
            element={
              <Suspense fallback={<>...</>}>
                <Register />
              </Suspense>
            }
          />
          {/* <Route
            path="changepassword"
            element={
              <Suspense fallback={<>...</>}>
                <ChangePassword />
              </Suspense>
            }
          /> */}
          <Route
            path="*"
            element={
              <Suspense fallback={<>...</>}>
                <NoPage />
              </Suspense>
            }
          />
        </Route>
        <Route path="/rms" element={<Layout />}>
          <Route
            index
            element={
              <Suspense fallback={<>...</>}>
                <Dashboard />
              </Suspense>
            }
          />

          <Route
            path="restaurant"
            element={
              <Suspense fallback={<>...</>}>
                <Restaurant />
              </Suspense>
            }
          />
          <Route
            path="users"
            element={
              <Suspense fallback={<>...</>}>
                <Users />
              </Suspense>
            }
          />
          <Route
            path="menu"
            element={
              <Suspense fallback={<>...</>}>
                <Menu />
              </Suspense>
            }
          />
          <Route
            path="order"
            element={
              <Suspense fallback={<>...</>}>
                <Order />
              </Suspense>
            }
          />
          <Route
            path="invoice"
            element={
              <Suspense fallback={<>...</>}>
                <Invoice />
              </Suspense>
            }
          />
          <Route
            path="setting"
            element={
              <Suspense fallback={<>...</>}>
                <Settings />
              </Suspense>
            }
          />
          <Route
            path="*"
            element={
              <Suspense fallback={<>...</>}>
                <NoPage />
              </Suspense>
            }
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default Routers;
