import React from "react";
import "../styles/components/Footer.css";
import {
  FaFacebookF,
  FaTwitter,
  FaGithub,
  FaMobileAlt,
  FaLinkedinIn,
  FaWhatsapp,
  FaShare,
} from "react-icons/fa";
import { AiOutlineMail } from "react-icons/ai";

function Footer(props) {
  const shareHandler = () => {
    navigator.share({
      title: "RMS APP",
      text: "RMS APP - make easy inventory",
      url: window.location.href,
    });
  };
  return (
    <div className="footer" style={props.style}>
      {/* <div className="text-content">Follow Me</div> */}
      <div className="icons-list">
        <a
          href="https://www.linkedin.com/in/manojchaurasiya/"
          target="_blank"
          rel="noopener noreferrer"
          className="icon"
        >
          <FaLinkedinIn className="icon-btn" color="white" />
        </a>
        <a
          href="https://github.com/riaconsultant/"
          target="_blank"
          rel="noopener noreferrer"
          className="icon"
        >
          <FaGithub className="icon-btn" color="white" />
        </a>

        <a
          href="tel:+919632444226"
          target="_blank"
          rel="noopener noreferrer"
          className="icon"
        >
          <FaMobileAlt className="icon-btn" color="white" />
        </a>
        <a
          href="mailto:manoj.chaurasiya@gmail.com"
          target="_blank"
          rel="noopener noreferrer"
          className="icon"
        >
          <AiOutlineMail className="icon-btn" color="white" />
        </a>

        <a
          href="https://twitter.com/manojchaurasiya"
          target="_blank"
          rel="noopener noreferrer"
          className="icon"
        >
          <FaTwitter className="icon-btn" color="white" />
        </a>

        <a
          href="https://www.facebook.com/manoj.chaurasiya"
          target="_blank"
          rel="noopener noreferrer"
          className="icon"
        >
          <FaFacebookF className="icon-btn" color="white" height="25px" />
        </a>
        <a
          href="https://api.whatsapp.com/send?phone=9632444226"
          target="_blank"
          rel="noopener noreferrer"
          className="icon"
        >
          <FaWhatsapp className="icon-btn" color="white" />
        </a>
        <FaShare className="icon-btn" color="white" onClick={shareHandler} />
      </div>
    </div>
  );
}

export default Footer;
