const OrderItem = () => {
  return (
    <tr>
      <td>3214</td>
      <td>Customer example</td>
      <td>$500.00</td>
      <td>03/04/2015</td>
      <td>03/05/2015</td>
      <td>
        <span className="label label-primary">Pending</span>
      </td>
      <td className="text-right">
        <div className="btn-group">
          <button className="btn-white btn btn-xs">View</button>
          <button className="btn-white btn btn-xs">Edit</button>
          <button className="btn-white btn btn-xs">Delete</button>
        </div>
      </td>
    </tr>
  );
};

export default OrderItem;
