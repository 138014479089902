const MenuForm = (prop) => {
  return (
    <div className="ibox">
      <div className="ibox-title">
        <h5>Menu Form</h5>
        <small>Create Menu Form</small>
      </div>
      <div class="ibox-content">
        <form>
          <div class="form-group">
            <label for="exampleInputEmail1">Email address</label>
            <input
              type="email"
              class="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              placeholder="Enter email"
            />
            <small id="emailHelp" class="form-text text-muted">
              We'll never share your email with anyone else.
            </small>
          </div>
          <button type="submit" class="btn btn-primary">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};
export default MenuForm;
