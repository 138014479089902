import axios from "axios";
import { useEffect, useState } from "react";
import RestaurantList from "../components/RestaurantList";
import Constants from "../config/Constants";
const Restaurant = () => {
  const [rest, setRest] = useState([]);
  const [fetchData, setFetchData] = useState(null);
  const url = new Constants();
  useEffect(() => {
    if (!fetchData) {
      axios.get(url.getUrl("restaurant")).then((res) => {
        setRest(res.data);
        setFetchData(true);
      });
    }
  }, [fetchData]);
  return (
    <div className="wrapper wrapper-content animated fadeInRight ecommerce">
      <label>Restaurant's List</label>
      <RestaurantList rest={rest} />
    </div>
  );
};

export default Restaurant;
