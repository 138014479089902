import { Outlet } from "react-router-dom";
import { Footer, Header } from "../components";
import Navigation from "../components/Navigation";
import axios from "axios";

axios.interceptors.request.use(
  (req) => {
    req.params = {
      api_token: localStorage.getItem("token"),
    };
    return req;
  },
  (err) => {
    return Promise.reject(err);
  }
);

// For POST requests
// axios.interceptors.response.use(
//   (res) => {
//     // Add configurations here
//     if (res.status === 201) {
//       console.log("Posted Successfully");
//     }
//     return res;
//   },
//   (err) => {
//     return Promise.reject(err);
//   }
// );

const Layout = (props) => {
  return (
    <div id="wrapper">
      <Navigation />
      <div id="page-wrapper" className="gray-bg">
        <Header />
        <Outlet />
        <Footer />
      </div>
    </div>
  );
};

export default Layout;
