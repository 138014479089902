import { Link } from "react-router-dom";

const Register = () => {
  return (
    <div className="middle-box text-center loginscreen   animated fadeInDown">
      <div>
        <div>
          <h1 className="logo-name">RM</h1>
        </div>
        <h3>Register to RMS</h3>
        <p>Create account to see it in action.</p>
        <form className="m-t">
          <div className="form-group">
            <input
              type="number"
              className="form-control"
              placeholder="Mobile"
              required=""
            />
          </div>
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              placeholder="Name"
              required=""
            />
          </div>
          <div className="form-group">
            <input
              type="email"
              className="form-control"
              placeholder="Email"
              required=""
            />
          </div>
          <div className="form-group">
            <input
              type="password"
              className="form-control"
              placeholder="Password"
              required=""
            />
          </div>
          <div className="form-group">
            <div className="checkbox i-checks">
              <label>
                {" "}
                <input type="checkbox" />
                <i></i> Agree the terms and policy{" "}
              </label>
            </div>
          </div>
          <button
            type="submit"
            className="btn btn-primary block full-width m-b"
          >
            Register
          </button>

          <p className="text-muted text-center">
            <small>Already have an account?</small>
          </p>
          <Link className="btn btn-sm btn-white btn-block" to="/">
            Login
          </Link>
        </form>
        <p className="m-t">
          <small>RMS App &copy; 2022</small>
        </p>
      </div>
    </div>
  );
};

export default Register;
