import { Link, unstable_HistoryRouter } from "react-router-dom";
import { Footer } from "../components";
import Login from "../components/Login";

const LoginPage = () => {
  return (
    <div className="middle-box text-center loginscreen animated fadeInDown">
      <div>
        <div>
          <h1 className="logo-name">RM</h1>
        </div>
        <h3>Welcome to RMS</h3>
        <p>
          Perfectly designed and precisely prepared for Restaurant And it's
          functions.
        </p>
        <p>Login in. To see it in action.</p>
        <Login />
        <Link to="/forgotpassword">Forgot password?</Link>
        <p className="text-muted text-center">
          <small>Do not have an account?</small>
        </p>
        <Link to="/register" className="btn btn-sm btn-white btn-block">
          Create an account
        </Link>
      </div>
      <Footer style={{ position: "unset" }} />
    </div>
  );
};

export default LoginPage;
