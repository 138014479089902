import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import useFetch from "../hooks/useFetch";
import * as Constant from "../config/Configs";
import * as Utils from "../config/Utils";
const Login = () => {
  const [loginState, setLoginState] = useState({});
  const [error, setError] = useState({});
  const navigate = useNavigate();
  const loginHandler = (evt) => {
    evt.preventDefault();
    if (loginState.mobile.length === 10 && loginState.pwd !== "") {
      fetch(Constant.AUTH_URL, Utils.getHeader("post", loginState))
        .then((res) => res.json())
        .then((data) => {
          if (data.message) {
            setError({ server: data.message });
          }
          if (data.token) {
            const { token, ...profile } = data;
            localStorage.setItem("token", token);
            localStorage.setItem("profile", JSON.stringify(profile));
            navigate("/rms", { replace: true });
          }
        })
        .catch((err) => {});
    }
  };
  useEffect(() => {
    if (localStorage.getItem("token")) {
      navigate("/rms", { replace: true });
    }
  });

  const showNotification = () => {
    var options = {
      body: "This is the body of the Notification",
      icon: "https://images.pexels.com/photos/853168/pexels-photo-853168.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
      dir: "ltr",
    };
    var notification = new Notification("Notification Demo", options);
  };

  const changeHandler = (evt) => {
    const { name, value } = evt.target;
    setLoginState((stat) => {
      return { ...stat, [name]: value };
    });
    if (name === "mobile") {
      if (value.length < 10) {
        setError((prev) => {
          return {
            ...prev,
            mobile: "Invalid Mobile number.",
            mclass: "invalid-feedback",
          };
        });
      } else {
        setError((prev) => {
          return { ...prev, mobile: "looks good.", mclass: "valid-feedback" };
        });
      }
    }
    if (name === "pwd") {
      if (value.length < 4) {
        setError((prev) => {
          return {
            ...prev,
            pwd: "Invalid Password.",
            pclass: "invalid-feedback",
          };
        });
      } else {
        setError((prev) => {
          return { ...prev, pwd: "looks good.", pclass: "valid-feedback" };
        });
      }
    }
  };

  return (
    <form
      onSubmit={loginHandler}
      className="m-t needs-validation was-validated"
      noValidate
    >
      {error.server && <div className="alert alert-danger">{error.server}</div>}
      <div className="form-group">
        <input
          type="text"
          name="mobile"
          className="form-control"
          placeholder="Mobile No"
          maxLength={10}
          minLength="10"
          required={true}
          onChange={changeHandler}
        />
        {error.mobile && <div className={error.mclass}>{error.mobile}</div>}
      </div>
      <div className="form-group">
        <input
          type="password"
          name="pwd"
          className="form-control"
          placeholder="Password"
          minLength="4"
          required={true}
          onChange={changeHandler}
        />
        {error.pwd && <div className={error.pclass}>{error.pwd}</div>}
      </div>

      <button type="submit" className="btn btn-primary block full-width m-b">
        Login
      </button>
      {/* <button onClick={showNotification}> Notification</button> */}
    </form>
  );
};

export default Login;
