import { Link, useNavigate } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();
  const logoutHandler = () => {
    localStorage.removeItem("token");
    localStorage.clear();
    navigate("/");
  };
  return (
    <div className="row border-bottom">
      <div className="navbar navbar-static-top" role="navigation">
        <div className="navbar-header">
          <Link
            to="."
            className="navbar-minimalize minimalize-styl-2 btn btn-primary "
          >
            <i className="fa fa-bars"></i>
          </Link>
        </div>
        <ul className="nav navbar-top-links navbar-right">
          <li>
            <span className="m-r-sm text-muted welcome-message">
              Welcome to RMS Application.
            </span>
          </li>
          <li>
            <Link to="/" onClick={logoutHandler}>
              <i className="fa fa-sign-out"></i> Log out
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Header;
