import axios from "axios";
import { useEffect, useState } from "react";
import DataList from "../components/DataList";
import MenuForm from "../components/MenuForm";
import Constants from "../config/Constants";

const Menu = () => {
  const [menus, setMenus] = useState();
  const url = new Constants();
  const object = [
    { id: "id", field: "id", header: "Id" },
    { id: "title", field: "title", header: "Name" },
    { id: "details", field: "details", header: "Detail" },
    { id: "desc", field: "desc", header: "Description" },
    { id: "status", field: "status", header: "Status" },
  ];
  useEffect(() => {
    if (!menus) {
      axios.get(url.getUrl("menu")).then((res) => {
        setMenus(res.data);
      });
    }
  }, [menus]);
  return (
    <div className="wrapper wrapper-content animated fadeInRight ecommerce">
      <label>Menu</label>
      <MenuForm />
      <DataList data={menus} object={object} />
    </div>
  );
};

export default Menu;
