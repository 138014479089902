class Constants {
  base = "http://localhost:3000/api/";
  url = {
    user: "user",
    restaurant: "restaurant",
    restaurantShort: "restaurant/short",
    menu: "menu",
    order: "order",
    invoice: "invoice",
    customer: "customer",
  };

  getUrl(arg) {
    switch (arg) {
      case "user":
        return `${this.base}${this.url.user}`;
      case "restaurant":
        return `${this.base}${this.url.restaurant}`;
      case "restaurantShort":
        return `${this.base}${this.url.restaurantShort}`;
      case "menu":
        return `${this.base}${this.url.menu}`;
      case "order":
        return `${this.base}${this.url.order}`;
      case "invoice":
        return `${this.base}${this.url.invoice}`;
      case "customer":
        return `${this.base}${this.url.customer}`;
      default:
        break;
    }
  }
}

export default Constants;
