import axios from "axios";
import { useEffect, useState } from "react";
import DataList from "../components/DataList";
import Constants from "../config/Constants";

const Users = () => {
  const [users, setUsers] = useState();
  const url = new Constants();
  const object = [
    { id: "id", field: "id", header: "Id" },
    { id: "mobile", field: "mobile", header: "Mobile" },
    { id: "Restaurant", field: "Restaurant", header: "Restaurant" },
    { id: "status", field: "status", header: "Status" },
  ];
  useEffect(() => {
    if (!users) {
      axios.get(url.getUrl("user")).then((res) => {
        setUsers(res.data);
      });
    }
  }, [users]);
  return (
    <div className="wrapper wrapper-content animated fadeInRight ecommerce">
      <label>Users</label>
      <DataList data={users} object={object} />
    </div>
  );
};

export default Users;
