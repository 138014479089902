import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import Routers from "./layouts/Routers";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAWLhMuIo-5b0oBwapQccWJfj9LLA0XdzI",
  authDomain: "rmsi-85605.firebaseapp.com",
  projectId: "rmsi-85605",
  storageBucket: "rmsi-85605.appspot.com",
  messagingSenderId: "370583186523",
  appId: "1:370583186523:web:5c60cca47ae60364b6e37e",
  measurementId: "G-D415TPY33D",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
// console.log("firebase app ", app, analytics);
if (!("Notification" in window)) {
  console.log("This browser does not support desktop notification");
} else {
  Notification.requestPermission();
}
ReactDOM.render(
  <React.StrictMode>
    <Routers />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
