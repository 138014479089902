import OrderItem from "./OrderItem";

const OrderList = () => {
  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="ibox">
          <div className="ibox-content">
            <table
              className="footable table table-stripped toggle-arrow-tiny"
              data-page-size="15"
            >
              <thead>
                <tr>
                  <th>Order ID</th>
                  <th data-hide="phone">Customer</th>
                  <th data-hide="phone">Amount</th>
                  <th data-hide="phone">Date added</th>
                  <th data-hide="phone,tablet">Date modified</th>
                  <th data-hide="phone">Status</th>
                  <th className="text-right">Action</th>
                </tr>
              </thead>
              <tbody>
                <OrderItem />
                <OrderItem />
                <OrderItem />
                <OrderItem />
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderList;
