import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";

const DataList = (prop) => {
  console.log("--", prop);
  return (
    <div className="content-section implementation datatable-doc-demo">
      <div className="card">
        <DataTable
          value={prop.data}
          filterDisplay="menu"
          selectionMode="single"
          resizableColumns
          reorderableColumns
          scrollable
          scrollHeight="400px"
        >
          {prop.object.map((obj, i) => {
            return (
              <Column
                key={obj.id}
                field={obj.field}
                header={obj.header}
                sortable
                filter
              />
            );
          })}
        </DataTable>
      </div>
    </div>
  );
};
export default DataList;
