import { OrderSearch, OrderList } from "../components";

const Order = () => {
  return (
    <div className="wrapper wrapper-content animated fadeInRight ecommerce">
      {/* <OrderSearch /> */}

      <OrderList />
    </div>
  );
};

export default Order;
