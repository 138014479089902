import { Link, useLocation } from "react-router-dom";

const Navigation = () => {
  const loc = useLocation();
  const subPath = loc.pathname.split("/");
  return (
    <div className="navbar-default navbar-static-side" role="navigation">
      <div className="sidebar-collapse">
        <ul className="nav metismenu" id="side-menu">
          <li className="nav-header">
            <div className="dropdown profile-element">
              {/* <img
                alt="profile user name"
                className="rounded-circle"
                src="/assets/img/profile_small.jpg"
              /> */}
              <Link to="." data-toggle="dropdown" className="dropdown-toggle">
                <span className="block m-t-xs font-bold">Manoj Chaurasiya</span>
                <span className="text-muted text-xs block">
                  Art Director <b className="caret"></b>
                </span>
              </Link>
              <ul className="dropdown-menu animated fadeInRight m-t-xs">
                <li>
                  <Link to="/profile" className="dropdown-item">
                    Profile
                  </Link>
                </li>
                <li>
                  <Link to="." className="dropdown-item">
                    Contacts
                  </Link>
                </li>
                <li>
                  <Link to="." className="dropdown-item">
                    Mailbox
                  </Link>
                </li>
                <li className="dropdown-divider"></li>
                <li>
                  <Link to="." className="dropdown-item">
                    Logout
                  </Link>
                </li>
              </ul>
            </div>
            <div className="logo-element">RM</div>
          </li>
          <li className={subPath[2] === "" ? "active" : ""}>
            <Link to="./">
              <i className="fa fa-th-large"></i>
              <span className="nav-label">Dashboards</span>
            </Link>
          </li>
          <li className={subPath[2] === "restaurant" ? "active" : ""}>
            <Link to="./restaurant">
              <i className="fa fa-diamond"></i>
              <span className="nav-label">Restaurants</span>
            </Link>
          </li>
          <li className={subPath[2] === "users" ? "active" : ""}>
            <Link to="./users">
              <i className="fa fa-diamond"></i>
              <span className="nav-label">Users</span>
            </Link>
          </li>
          <li className={subPath[2] === "menu" ? "active" : ""}>
            <Link to="./menu">
              <i className="fa fa-diamond"></i>
              <span className="nav-label">Menus</span>
            </Link>
          </li>
          <li className={subPath[2] === "order" ? "active" : ""}>
            <Link to="./order">
              <i className="fa fa-bar-chart-o"></i>
              <span className="nav-label">Order</span>
            </Link>
          </li>
          <li className={subPath[2] === "invoice" ? "active" : ""}>
            <Link to="./invoice">
              <i className="fa fa-table"></i>
              <span className="nav-label">Invoice</span>
            </Link>
          </li>
          <li className={subPath[2] === "setting" ? "active" : ""}>
            <Link to="./setting">
              <i className="fa fa-cog"></i>
              <span className="nav-label">Setting</span>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Navigation;
